import { LocalStorageItem } from '../config/app.config';

export namespace Tokens {
  export interface TokenSet {
    token: string;
    refreshtoken: string;
    username: string;
  }

  export function store(data: TokenSet) {
    if (!validate(data)) {
      return false;
    }
    localStorage.setItem(LocalStorageItem.AccessToken, data.token);
    localStorage.setItem(LocalStorageItem.RefreshToken, data.refreshtoken);
    localStorage.setItem(LocalStorageItem.Username, data.username);
    return true;
  }

  export function getStored(): TokenSet | null {
    const data = {
      token: localStorage.getItem(LocalStorageItem.AccessToken),
      refreshtoken: localStorage.getItem(LocalStorageItem.RefreshToken),
      username: localStorage.getItem(LocalStorageItem.Username),
    };
    const ans = validate(data);
    if (!ans) {
      removeAll();
    }
    return ans;
  }

  export function validate(data: any) {
    const token = data['token'] as any;
    const refreshtoken = data['refreshtoken'] as any;
    const username = data['username'] as any;
    if (
      token &&
      typeof token === 'string' &&
      refreshtoken &&
      typeof refreshtoken === 'string' &&
      username &&
      typeof username === 'string'
    ) {
      return { token, refreshtoken, username };
    }
    return null;
  }

  export function removeAll() {
    localStorage.removeItem(LocalStorageItem.AccessToken);
    localStorage.removeItem(LocalStorageItem.RefreshToken);
    localStorage.removeItem(LocalStorageItem.Username);
  }

  export function forAccess() {
    return getStored()?.token;
  }
}
