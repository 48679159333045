import { Injectable } from '@angular/core';
import { BackendService } from '../services';
import { AppAction } from './app.models';
import { Actions } from '@ngrx/effects';

@Injectable()
export class AppEffects {
  constructor(
    private bs: BackendService,
    private as: Actions<AppAction>
  ) {}
}
