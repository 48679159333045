import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Event } from '../models';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(private bs: BackendService) {}

  // Retrieve all events
  async getEvents() {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.event.list));
    const events: Event[] = (res as any[]).map((p: any) => new Event(p));
    return events;
  }

  // Retrieve events filtered by venue
  async getEventsByVenue(venueId: string | number) {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.event.list));
    const events: Event[] = (res as any[]).map((p: any) => new Event(p));

    return events.filter(e => {
      // Filter events by venue if a specific venue is provided
      if (venueId !== -1 && e.venueid != venueId) return false;
      return true;
    });
  }

  // Retrieve a single event by ID
  async getEvent(id: string | number) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.event.get(id))
    );
    const event = res ? new Event(res) : undefined;
    return event;
  }

  // Update an existing event
  async updateEvent(id: string | number, postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.put(this.bs.Api.event.get(id), postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      data: data,
    };
  }

  // Create a new event
  async createEvent(postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.post(this.bs.Api.event.list, postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      id: data.id ? data.id : data.insertId,
    };
  }
}
