import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Api, ApiLocal } from 'src/app/models';
import { CustomHttpParamEncoder } from '../models/util/encoder';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';

const server = environment.server;
@Injectable()
export class BackendService {
  Api = environment.isLocal ? ApiLocal : Api;

  constructor(private http: HttpClient) {}

  get<T = any>(api: string, data: any = null, loading = api, email = false) {
    api = server + api;
    this.onStart(loading);
    return this.http
      .get<{ data: T }>(
        api,
        data
          ? {
              params: email
                ? new HttpParams({
                    encoder: new CustomHttpParamEncoder(),
                    fromObject: data,
                  })
                : data,
            }
          : {}
      )
      .pipe(
        catchError((err: any) => this.handleError(err)),
        finalize(() => this.onEnd(loading))
      );
  }

  post<T = any>(
    api: string,
    data: any = null,
    loading: string | null = api,
    rtc = false
  ) {
    api = server + api;
    this.onStart(loading);
    return this.http.post(api, data).pipe(
      map((res: any) => res),
      catchError(err => this.handleError(err)),
      finalize(() => this.onEnd(loading))
    );
  }

  put(api: string, data: any = null, loading = api, rtc = false) {
    api = server + api;
    this.onStart(loading);
    return this.http.put(api, data).pipe(
      map((res: any) => res.data),
      catchError(err => this.handleError(err)),
      finalize(() => this.onEnd(loading))
    );
  }

  delete(api: string, data: any = null, loading = api) {
    api = server + api;
    this.onStart(loading);
    return this.http.delete(api, data ? { params: data } : {}).pipe(
      map((res: any) => res.data),
      catchError(err => this.handleError(err)),
      finalize(() => this.onEnd(loading))
    );
  }

  upload(
    api: string,
    file: File,
    filename: string | undefined = undefined,
    loading = api
  ) {
    api = server + api;
    this.onStart(loading);
    const formData = new FormData();
    formData.append('file', file, filename ? filename : file.name);
    return this.http.post(api, formData, { responseType: 'text' }).pipe(
      map((res: any) => res.data),
      catchError(err => this.handleError(err)),
      finalize(() => this.onEnd(loading))
    );
  }

  download(
    api: string,
    data: any = {},
    loading = api
  ): Observable<ArrayBuffer | Blob> {
    api = server + api;
    this.onStart(loading);
    return this.http
      .get(api, {
        params: data,
        responseType: 'blob',
      })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => this.onEnd(loading))
      );
  }

  private onStart(loading: string | null = null) {
    // update global state -> api call start
  }
  private onEnd(loading: string | null = null) {
    // update global state -> api call end
  }

  private handleError(res: Response & { error?: any }) {
    return throwError(res.error || '');
  }
}
