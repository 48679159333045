import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { StoreService } from './store.service';
import { BackendService } from './backend.service';

@Injectable()
export class InitializeService {
  constructor(
    private auth: AuthService,
    private store: StoreService,
    private bs: BackendService
  ) {}

  async run() {
    const pros: Promise<any>[] = [];
    pros.push(this.auth.logInByToken());
    await Promise.all(pros);
  }
}
