import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class UploadService {
  constructor(private bs: BackendService) {}

  async uploadVenueImage(file: File) {
    const uuid =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(23).substring(2, 5);
    const ext = file.name.split('.').pop();
    let filename = `${uuid}.${ext}`;

    let error: any;
    const data = await firstValueFrom(
      this.bs.upload(this.bs.Api.upload.venue, file, filename)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error: error.Message ? error.Message : 'Something went wrong',
      };
    }

    return {
      success: true,
      filename: `${environment.resources}/venues/${filename}`

    };
  }

  async uploadProductImage(file: File, venueid: string | number) {
    const uuid =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(23).substring(2, 5);
    const ext = file.name.split('.').pop();
    let filename = `${uuid}.${ext}`;

    let error: any;
    const data = await firstValueFrom(
      this.bs.upload(this.bs.Api.upload.product(venueid), file, filename)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error: error.Message ? error.Message : 'Something went wrong',
      };
    }

    return {
      success: true,
      filename: `${venueid}/${filename}`,
    };
  }
  async uploadEventImage(file: File) {
    const uuid =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(23).substring(2, 5);
    const ext = file.name.split('.').pop();
    let filename = `${uuid}.${ext}`;

    let error: any;
    const data = await firstValueFrom(
      this.bs.upload(this.bs.Api.upload.event, file, filename)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error: error.Message ? error.Message : 'Something went wrong',
      };
    }

    return {
      success: true,
      filename: `${environment.resources}/event/${filename}`,
    };
  }

  async uploadUserImage(file: File) {
    const uuid =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(23).substring(2, 5);
    const ext = file.name.split('.').pop();
    let filename = `${uuid}.${ext}`;

    let error: any;
    const data = await firstValueFrom(
      this.bs.upload(this.bs.Api.upload.user, file, filename)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error: error.Message ? error.Message : 'Something went wrong',
      };
    }

    return {
      success: true,
      filename: `${environment.resources}/user/${filename}`,
    };
  }
}
