import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { throwError, catchError, take, switchMap, Subject } from 'rxjs';
import { BackendService } from './';
import { AuthService } from './auth.service';
import { HttpStatusCodes as Status, Api } from '../models';
import { Tokens } from '../models/util/tokens';

/** TODO: This is a temporary solution. A long term solution can be devised using the `HttpContext` */
const AVOID_ACCESS_TOKEN_REFRESH = new Set();

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private tokenRefreshed = new Subject<true>();

  constructor(
    private auth: AuthService,
    private bs: BackendService
  ) {}

  intercept<T>(req: HttpRequest<T>, next: HttpHandler) {
    if (req.url.includes(Api.auth.refresh)) {
      return next.handle(this.addAuthHeader(req));
    }
    if (this.isRefreshing) {
      return this.waitForTokenRefreshing(req, next);
    }
    return next.handle(this.addAuthHeader(req)).pipe(
      catchError(e => {
        if (
          !(e instanceof HttpErrorResponse) ||
          AVOID_ACCESS_TOKEN_REFRESH.has(req.url)
        ) {
          return throwError(e);
        }
        if (e.status === Status.Unauthorized) {
          if (this.isRefreshing) {
            return this.waitForTokenRefreshing(req, next);
          }
          return this.handle401Error(req, next);
        }
        return throwError(e);
      })
    );
  }

  private waitForTokenRefreshing<T>(req: HttpRequest<T>, next: HttpHandler) {
    return this.tokenRefreshed.pipe(
      take(1),
      switchMap(() => next.handle(this.addAuthHeader(req)))
    );
  }

  private handle401Error<T>(req: HttpRequest<T>, next: HttpHandler) {
    this.isRefreshing = true;
    const token = Tokens.getStored();
    return this.bs
      .post<null | { token?: Tokens.TokenSet }>(Api.auth.refresh, {
        refreshtoken: token?.refreshtoken,
      })
      .pipe(
        take(1),
        switchMap(data => {
          this.isRefreshing = false;
          if (
            !data ||
            !data.jwtToken ||
            !data.jwtRefreshToken ||
            !data.user ||
            !Tokens.store({
              token: data.jwtToken,
              refreshtoken: data.jwtRefreshToken,
              username: data.user.username,
            })
          ) {
            this.auth.logOut();
            return throwError('invalid token');
          }
          this.tokenRefreshed.next(true);
          return next.handle(this.addAuthHeader(req));
        })
      );
  }

  private addAuthHeader<T>(req: HttpRequest<T>) {
    const token = Tokens.forAccess();
    if (!token) {
      return req;
    }
    return req.clone({
      setHeaders: {
        Authorization: `${token}`,
      },
    });
  }
}
