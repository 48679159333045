import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { firstValueFrom, pipe, map } from 'rxjs';
import { Report } from '../models';

@Injectable()
export class ReportService {
  constructor(private bs: BackendService) {}

  // Retrieve revenue
  async getRevenue() {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.report.revenue));
    const report: Report[] = res.map((p: any) => new Report(p));
    return {
      report
    };
  }

  // Retrieve sales
  async getSales() {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.report.sales));
    const report: Report[] = res.map((p: any) => new Report(p));
    return {
      report
    };
  }

  // Retrieve customers
  async getCustomers() {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.report.customers));

    const report: Report[] = res.map((p: any) => new Report(p));
    return {
      report
    };
  }



  // Retrieve sales
  async getOrdersReport() {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.report.sales));

    return {
      res
    };
  }

  // Retrieve customers
  async getCustomersReport() {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.report.customers));

    return {
      res
    };
  }

   // Retrieve summary
   async getSummary(event: string) {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.report.summary(event)));

    return {
      res
    };
  }

     // Retrieve revenue report
     async getRevenueReport(event: string) {
      const res: any = await firstValueFrom(this.bs.get(this.bs.Api.report.revenuereport(event)));

      return {
        res
      };
    }

    // Retrieve concession report
    async getConcessionReport(event: string) {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.report.concessionreport(event)));

    return {
      res
    };
  }

   // Retrieve concession category report
  async getConcessionCategoryReport(event: string) {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.report.concessioncategoryreport(event)));

    return {
      res
    };
  }

   // Retrieve concession categories
   async getConcessionCategories(event: string) {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.report.concessioncategories(event)));

    return {
      res
    };
  }

   // Retrieve summary by concession
   async getConcessionSummary(concession: string) {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.report.concessionsummary(concession)));

    return {
      res
    };
  }

      // Retrieve revenue by concession report
      async getRevenueByConcessionReport(concession: string) {
      const res: any = await firstValueFrom(this.bs.get(this.bs.Api.report.revenuebyconcessionreport(concession)));

      return {
        res
      };
    }

}
