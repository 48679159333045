import { Injectable } from '@angular/core';
import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Tokens } from 'src/app/models/util/tokens';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: StoreService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = Tokens.getStored();
    if (!token) {
      this.router.navigate(['/auth/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }

    const user = await firstValueFrom(
      this.store.select(this.store.Mod.App, this.store.Fields.app.User)
    );
    const roles = user?.role ?? ['venue_manager'];
    if (roles.includes('admin')) {
      return true;
    }

    // not logged in so redirect to login page with the return url

    return false;
  }
}
