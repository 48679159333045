export const ApiLocal = {
  account: {},
  auth: {
    logIn: () => `assets/data/auth.login.json`,
    loginByToken: (isAdmin: boolean) =>
      `assets/data/auth${isAdmin ? '-admin' : ''}.access-token.json`,
    logOut: `assets/data/auth.log-out.json`,
    refresh: `assets/data/auth/refresh-token`,
  },
  apikey: {
    list: '/apikeys',
    get: (id: string | number) => `/apikeys/${id}`,
    delete: (id: string | number) => `/apikeys/${id}`,
    new: (venue: number) => `/apikey/${venue}`,
  },
  venue: {
    list: 'assets/data/venues.json',
    get: (id: string | number) => `assets/data/venue.json`,
  },
  notification: {
    list: '/notifications',
    get: (id: number | string) => `/notification/${id}`,
    event: (id: number | string) => `/notifications/event/${id}`,
    venue: (id: number | string) => `/notifications/venue/${id}`,
  },
  order: {
    all: `/orders`,
    get: (id: string) => `assets/data/order.json`,
    list: (page: number) => `assets/data/orders-${page}.json`,
    items: (id: string) => `/orderitems/${id}`,
    venue: (id: string) => `/orders/venue/${id}`,
    openvenue: (id: string) => `/openorders/venue/${id}`,
    openevent: (id: string) => `/openorders/event/${id}`,
    concession: (id: string) => `/orders/concession/${id}`,
    cart: (id: string) => `/orders/cart/${id}`,
  },
  report: {
    revenue: `/reports/revenue`,
    sales: `/reports/sales`,
    customers: `/reports/customers`,
    summary: (id: string) => `/reports/summary/event/${id}`,
    revenuereport: (id: string) => `/reports/revenuereport/event/${id}`,
    revenuebyconcessionreport: (id: string) => `/reports/revenuebyconcessionreport/concession/${id}`,
    concessionreport: (id: string) => `/reports/concessionreport/event/${id}`,
    concessioncategoryreport: (id: string) => `/reports/concessioncategoryreport/event/${id}`,
    concessioncategories: (id: string) => `/reports/concessioncategories/event/${id}`,
    concessionsummary: (id: string) => `/reports/summary/concession/${id}`,
  },
  pickup: {
    list: `assets/data/pickups.json`,
    get: (id: number | string) => `assets/data/product.json`,
    venue: (id: number | string) => `/pickups/venue/${id}`,
    concession: (id: number | string) => `/pickups/concession/${id}`,
  },
  product: {
    all: `assets/data/products.json`,
    get: (id: number | string) => `assets/data/product.json`,
    list: (page: number, category: number | string) =>
      `assets/data/products-${
        category != -1 ? `category-${category}-` : ''
      }${page}.json`,
  },
  optionset: {
    list: `assets/data/optionsets.json`,
    get: (id: number | string) => `assets/data/optionsets.json`,
  },
  option: {
    get: (id: number | string) => `assets/data/option.json`,
    list: `assets/data/options.json`,
  },
  inventory: {
    list: `assets/data/inventory.json`,
    get: (id: number | string) => `/inventory/${id}`,
    concession: (id: number | string) => `/inventory/concession/${id}`,
    venue: (id: number | string) => `/products/venue/${id}`,
  },
  concession: {
    get: (id: number | string) => `assets/data/concession-${id}.json`,
    list: `assets/data/concessions.json`,
    event: (id: string) => `assets/data/concessions-event-${id}.json`,
  },
  marker: {
    get: (id: number | string) => `assets/data/concession-${id}.json`,
    list: `assets/data/concessions.json`,
    event: (id: string) => `assets/data/concessions-event-${id}.json`,
  },
  cart: {
    get: (id: number | string) => `assets/data/concession-${id}.json`,
    list: `assets/data/concessions.json`,
    event: (id: string) => `assets/data/concessions-event-${id}.json`,
    eventactive: (id: string) => `/activecarts/event/${id}`,
  },
  event: {
    list: `assets/data/events.json`,
    get: (id: number | string) => `/event/${id}`,
  },
  settings: {
    get: `/config`,
  },
  user: {
    list: `/users`,
    managerlist:  (venueid: string) => `/managers/${venueid}`,
    employeelist:  (venueid: string) => `/employees/${venueid}`,
    create: '/users',
    update: (id: number | string) => `/users/${id}`,
    get: (id: number | string) => `assets/data/user.json`,
    getByUsername: (username: string) => `/user/username/${username}`,
  },
  employee: {
    update: (id: number | string) => `/employee/${id}`,
  },
  venueAdmin: {
    venue: (userid: number | string, venueid: number | string) =>
      `/venuemanager/${userid}/venue/${venueid}`,
  },
  images: {
    icon: `/images/icon`,
    image: `/images/image`,
  },
  upload: {
    venue: `/s3/upload/folder/venues`,
    event: `/s3/upload/folder/event`,
    user: `/s3/upload/folder/user`,
    product: (venueid: number | string) =>
      `/s3/upload/folder/inventory~${venueid}`,
  },
};
