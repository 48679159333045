import { LocalStorageItem } from '../config';

export class Venue {
  id: string;
  name: string;
  venuetype: string;
  status: string;
  address: string;
  description: string;
  city: string;
  state: string;
  zipcode: string;
  website: string;
  latitude: number;
  longitude: number;
  courseimage: string;
  phonenumber: string;
  email: string;
  firstname: string;
  lastname: string;
  active: boolean;
  charge: number;
  tax: number;

  constructor(data: any = {}) {
    if (!data) {
      data = {};
    }
    this.id = data.venue ? data.venue : data.id;
    this.name = data.name;
    this.venuetype = data.venuetype;
    this.active = data.active;
    this.address = data.address;
    this.description = data.description;
    this.city = data.city;
    this.state = data.state;
    this.zipcode = data.zipcode;
    this.website = data.website ?? '';
    this.latitude = data.latitude ?? 32.460006;
    this.longitude = data.longitude ?? -111.071467;
    this.courseimage = data.courseimage;
    this.phonenumber = data.phonenumber;
    this.email = data.email;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.active = !!data.active;
    this.tax = data.tax ?? 0;
    this.charge = data.charge ?? 0;
  }

  static storeVenueId(id: string) {
    localStorage.setItem(LocalStorageItem.VenueId, id);
  }

  public static getStoredVenueId(): string | null {
    const id = localStorage.getItem(LocalStorageItem.VenueId);
    if (id) return id;
    return null;
  }
}
