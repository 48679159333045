export const Api = {
  auth: {
    logIn: () => `/login`,
    loginByToken: (isAdmin: boolean) => `/token`,
    logOut: `/auth/log-out`,
    refresh: `/refreshtoken`,
  },
  apikey: {
    list: '/apikeys',
    get: (id: string | number) => `/apikeys/${id}`,
    delete: (id: string | number) => `/apikeys/${id}`,
    new: (venue: number) => `/apikey/${venue}`,
  },
  venue: {
    list: '/venues',
    get: (id: string | number) => `/venue/${id}`,
  },
  notification: {
    list: '/notifications',
    get: (id: number | string) => `/notification/${id}`,
    event: (id: number | string) => `/notifications/event/${id}`,
    venue: (id: number | string) => `/notifications/venue/${id}`,
  },
  order: {
    all: `/orders`,
    get: (id: string) => `/order/${id}`,
    list: (page: number) => `/orders`,
    items: (id: string) => `/orderitems/${id}`,
    venue: (id: string) => `/orders/venue/${id}`,
    openvenue: (id: string) => `/openorders/venue/${id}`,
    openevent: (id: string) => `/openorders/event/${id}`,
    concession: (id: string) => `/orders/concession/${id}`,
    cart: (id: string) => `/orders/cart/${id}`,
  },
  report: {
    revenue: `/reports/revenue`,
    sales: `/reports/sales`,
    customers: `/reports/customers`,
    summary: (id: string) => `/reports/summary/event/${id}`,
    revenuereport: (id: string) => `/reports/revenuereport/event/${id}`,
    revenuebyconcessionreport: (id: string) => `/reports/revenuebyconcessionreport/concession/${id}`,
    concessionreport: (id: string) => `/reports/concessionreport/event/${id}`,
    concessioncategoryreport: (id: string) => `/reports/concessioncategoryreport/event/${id}`,
    concessioncategories: (id: string) => `/reports/concessioncategories/event/${id}`,
    concessionsummary: (id: string) => `/reports/summary/concession/${id}`,
  },
  pickup: {
    list: `/pickups`,
    get: (id: number | string) => `/pickups/${id}`,
    venue: (id: number | string) => `/pickups/venue/${id}`,
    concession: (id: number | string) => `/pickups/concession/${id}`,
  },
  product: {
    all: `/products`,
    get: (id: number | string) => `/product/${id}`,
    list: (page: number, category: number | string) => `/products`,
  },
  optionset: {
    list: `/categories`,
    get: (id: number | string) => `/category/${id}`,
  },
  option: {
    get: (id: number | string) => `/extra/${id}`,
    list: `/extras`,
  },
  inventory: {
    list: `/inventory`,
    get: (id: number | string) => `/inventory/${id}`,
    concession: (id: number | string) => `/inventory/concession/${id}`,
    venue: (id: number | string) => `/products/venue/${id}`,
  },
  concession: {
    get: (id: number | string) => `/concession/${id}`,
    list: `/concessions`,
    event: (id: string) => `/concessions/event/${id}`,
  },
  marker: {
    get: (id: number | string) => `/marker/${id}`,
    list: `/markers`,
    event: (id: string) => `/markers/event/${id}`,
  },
  cart: {
    get: (id: number | string) => `/cart/${id}`,
    list: `/carts`,
    event: (id: string) => `/carts/event/${id}`,
    eventactive: (id: string) => `/activecarts/event/${id}`,
  },
  event: {
    list: `/events`,
    get: (id: number | string) => `/event/${id}`,
  },
  settings: {
    get: `/config`,
  },
  user: {
    list: `/users`,
    managerlist:  (venueid: string) => `/managers/${venueid}`,
    employeelist:  (venueid: string) => `/employees/${venueid}`,
    create: '/users',
    update: (id: number | string) => `/users/${id}`,
    get: (id: number | string) => `/users/${id}`,
    getByUsername: (username: string) => `/user/username/${username}`,
  },
  employee: {
    update: (id: number | string) => `/employee/${id}`,
  },
  venueAdmin: {
    venue: (userid: number | string, venueid: number | string) =>
      `/venuemanager/${userid}/venue/${venueid}`,
  },
  images: {
    icon: `/images/icon`,
    image: `/images/image`,
  },
  upload: {
    venue: `/s3/upload/folder/venues`,
    event: `/s3/upload/folder/event`,
    user: `/s3/upload/folder/user`,
    product: (venueid: number | string) =>
      `/s3/upload/folder/inventory~${venueid}`,
  },
};
