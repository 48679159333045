import { Inventory } from './inventory';
import { Product } from './product';
import { ProductOption } from './product-option';

export enum ProductOptionSetType {
  option = 1,
  checkbox = 2,
}

export class ProductOptionSet {
  id: string | number;
  title: string;
  name: string;
  description: string;
  choice: ProductOptionSetType;
  inventoryid: string | number;
  vieworder: number;

  inventory: Inventory;

  min: number;
  max: number;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.category;
      this.title = `${this.id} ${this.name}`;
      this.description = data.description;
      this.min = data.min;
      this.max = data.max;
      this.inventoryid = data.inventoryid;
      this.vieworder = data.vieworder;
      this.choice =
        ProductOptionSetType[data.choice as keyof typeof ProductOptionSetType];
    }
  }
}
