import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { firstValueFrom } from 'rxjs';
import { APIKeys } from '../models/entities/apikeys';
import { StoreService } from './store.service';

@Injectable()
export class APIKeysService {
  constructor(
    private bs: BackendService,
    private store: StoreService
  ) {}

  async getNewKey(venue: number) {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.apikey.new(venue)));
    return res;
  }

  // Retrieve all APIKeys
  async getAPIKeys(venue: any) {

    // Retrieve venues from the backend
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.apikey.list));

    // Fetch user information
    const user = await this.store.fetchUser();

    // Map backend response to Venue objects
    const allAPIKeys = res ? (res as any[]).map(v => new APIKeys(v)) : [];

    // If the user is an admin, return all venues
    //if (user.role === 'admin') {
      //return allAPIKeys;
    //}

    // Filter venues based on the user's associated venues
    return allAPIKeys.filter(e => venue === e.venue);
  }

  // Retrieve a single venue by ID
  async getAPIKey(id: string | number) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.apikey.get(id))
    );

    return new APIKeys(res);
  }

  // Create a new key
  async create(postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.post(this.bs.Api.apikey.list, postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }

    if (data) {
      return {
        success: true,
        id: data.id ? data.id : data.insertId,
      };
    }

    return {
      success: true,
    };
  }

  // Update an existing key
  async update(id: number | string, postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.put(this.bs.Api.apikey.get(id), postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      data: data,
    };
  }

// Deletee an existing key
async delete(id: number | string) {
  let error: any;
  const data = await firstValueFrom(
    this.bs.delete(this.bs.Api.apikey.delete(id))
  ).catch(e => {
    error = e;
    return null;
  });

  if (!!error) {
    return {
      success: false,
      error,
    };
  }

  return {
    success: true,
    data: data,
  };
}
}
