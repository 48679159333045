import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { LayoutModule } from './views/layout/layout.module';
import { AuthGuard } from './core/guard/auth.guard';

import { AppComponent } from './app.component';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';

import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BackendService } from './services';
import { TokenInterceptor } from './services/token-interceptor';
import { AuthService } from './services/auth.service';
import { StoreService } from './services/store.service';
import { appReducer } from './store/app.reducer';
import { AppEffects } from './store/app.effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { InitializeService } from './services/initialize.service';
import { AdminGuard } from './core/guard/admin.guard';
import { SellerOnlyGuard } from './core/guard/seller-only.guard';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

export function initializeFactory(init: InitializeService) {
  return () => init.run();
}

@NgModule({
  declarations: [AppComponent, ErrorPageComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    StoreModule.forRoot(
      {
        app: appReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ),
    EffectsModule.forRoot([AppEffects]),
  ],
  providers: [
    AuthGuard,
    AdminGuard,
    SellerOnlyGuard,
    BackendService,
    AuthService,
    StoreService,
    InitializeService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFactory,
      deps: [InitializeService],
      multi: true,
    },
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
