import { Product } from './product';

export class OrderItem {
  id: string;
  concessioninventoryid: number;
  orderid: number;
  price: number;
  category: string;
  name: string;
  value: number;
  itemdetails: string;
  inventoryid: number;
  totalprice: number;
  baseprice: number;

  constructor(data: any = {}) {
    this.id = data.id;
    this.concessioninventoryid = data.concessioninventoryid;
    this.orderid = data.orderid;
    this.price = data.price;
    this.category = data.category;
    this.name = data.name;
    this.value = data.value;
    this.itemdetails = data.itemdetails;
    this.inventoryid = data.inventoryid;
    this.totalprice = data.totalprice;
    this.baseprice = data.baseprice;
  }
}
