import { DatePipe, formatDate } from "@angular/common";
import { LOCALE_ID, NgModule } from '@angular/core';

export class Report {
  revenue: number;
  sales: number;
  customers: number;
  orderdate: any;
  pipe = new DatePipe('en-US'); // Use your own locale

  constructor(data: any = {}) {
    if (!data) {
      data = {};
    }

    this.revenue = data.revenue;
    this.orderdate = this.pipe.transform(data.orderdate, 'shortDate');
    this.sales = data.sales;
    this.customers = data.customers;
    //this.orderdate = this.pipe.transform(data.orderdate, 'short');
  }
}
