export class Resource {
  id: number;
  name: string;
  type: string;

  constructor(data?: any) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
  }
}
