import { Injectable } from '@angular/core';
import { StoreModuleType, User } from '../models';
import { AppAction, AppFields, AppState, AppTypes } from '../store';
import { Store } from '@ngrx/store';
import { Observable, firstValueFrom } from 'rxjs';

interface State {
  [StoreModuleType.App]: AppState;
}

type Actions = AppAction;
@Injectable()
export class StoreService {
  Mod = StoreModuleType;

  Fields = { app: AppFields };
  Effect = { app: AppTypes };
  Selectors = Object.values(StoreModuleType).map(
    module => (state: State) => state[module]
  );

  readonly user$: Observable<User | null>;

  constructor(private store: Store<State>) {
    this.user$ = store.select(this.Mod.App, this.Fields.app.User);
  }

  async fetchUser(): Promise<User>;
  async fetchUser(returnNull: true): Promise<User | null>;
  async fetchUser(returnNull?: true) {
    const ans = await this.fetch(this.Mod.App, this.Fields.app.User);
    if (returnNull) {
      return ans;
    }
    if (!ans) {
      throw Error('cannot fetch user in store');
    }
    return ans;
  }

  fetch<M extends keyof State, K extends keyof State[M]>(mod: M, prop: K) {
    return firstValueFrom(this.select(mod, prop));
  }

  select<M extends keyof State, K extends keyof State[M]>(mod: M, prop: K) {
    return this.store.select(state => state[mod][prop]);
  }

  dispatch(data: Actions) {
    return this.store.dispatch({
      type: data.type,
      payload: 'payload' in data ? data.payload : undefined,
    });
  }
}
