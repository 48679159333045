import { EventsModule } from './views/pages/events/events.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { AdminGuard } from './core/guard/admin.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./views/pages/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'venues',
        loadChildren: () =>
          import('./views/pages/venue/venue.module').then(m => m.VenueModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/pages/dashboard/dashboard.module').then(
            m => m.DashboardModule
          ),
      },
      {
        path: 'events',
        loadChildren: () =>
          import('./views/pages/events/events.module').then(
            m => m.EventsModule
          ),
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./views/pages/orders/orders.module').then(
            m => m.OrdersModule
          ),
      },
      {
        path: 'pickups',
        loadChildren: () =>
          import('./views/pages/pickups/pickups.module').then(
            m => m.PickupsModule
          ),
      },
      {
        path: 'items',
        loadChildren: () =>
          import('./views/pages/items/items.module').then(m => m.ItemsModule),
      },
      {
        path: 'concessions',
        loadChildren: () =>
          import('./views/pages/concession/concession.module').then(
            m => m.ConcessionModule
          ),
      },
      {
        path: 'beverage-carts',
        loadChildren: () =>
          import('./views/pages/concession/concession.module').then(
            m => m.ConcessionModule
          ),
      },
      {
        path: 'markers',
        loadChildren: () =>
          import('./views/pages/concession/concession.module').then(
            m => m.ConcessionModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./views/pages/users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'managers',
        loadChildren: () =>
          import('./views/pages/managers/managers.module').then(m => m.ManagersModule),
      },
      {
        path: 'report',
        loadChildren: () =>
          import('./views/pages/report/report.module').then(
            m => m.ReportModule
          ),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./views/pages/notifications/notifications.module').then(
            m => m.NotificationsModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./views/pages/settings/settings.module').then(
            m => m.SettingsModule
          ),
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ],
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: 'Page Not Found',
      desc: "Oopps!! The page you were looking for doesn't exist.",
    },
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent,
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
