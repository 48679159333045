import { createReducer } from '@ngrx/store';
import { AppAction, AppState, AppTypes, InitialState } from './app.models';

export function appReducer(s: AppState = InitialState, a: AppAction) {
  if (!s) {
    s = InitialState;
  }
  if (!a) {
    console.log('App module dispatched an invalid action.');
    return s;
  }
  switch (a.type) {
    case AppTypes.UpdateState:
      return { ...s, ...a.payload };
    default:
      return s;
  }
}
