export enum LocalStorageItem {
  LastLoginData = 'BevCartsLoginData',
  AccessToken = 'BevCartsAccess',
  RefreshToken = 'BevCartsRefresh',
  Username = 'BevCartsUsername',
  VenueId = 'BevCartsVenueId',
  EventId = 'BevCartsEventId',
}

export enum HttpStatusCodes {
  OK = 200,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  InternalServerError = 500,
}

export enum StoreModuleType {
  App = 'app',
}

export const authRoles = {
  admin: ['admin'],
  sellerOnly: ['venue_manager'],
};
