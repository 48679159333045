import { environment } from 'src/environments/environment';
import { Concession } from './concession';
import { Product } from './product';

export class Inventory {
  id: number;
  item: number | string;
  numberinstock: number;
  concession: number | string;
  title: string;
  concessionname: string;
  productname: string;
  name: string;
  image: string;
  thumbnail: string;
  category: string;
  price: number;
  venue: string | number;
  extracategory: number;
  extracategoryname: string;
  extraname: string;
  extratype: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.numberinstock = data.numberinstock;
      this.item = data.item;
      this.concession = data.concession;
      this.concessionname = data.concessionname;
      this.name = data.name;
      this.productname = data.productname;
      this.thumbnail = data.thumbnail;
      this.image = data.image;
      this.category = data.category;
      this.venue = data.venue;
      this.price = data.price;
      this.extracategory = data.extracategory;
      this.extracategoryname = data.extracategoryname;
      this.extraname = data.extraname;
      this.extratype = data.extratype;
      if (this.concessionname && this.productname) {
        this.title = `${this.concessionname} - ${this.productname}`;
      } else {
        this.title = `Concession ${data.concession} - Product ${data.item}`;
      }
    }
  }

  thumbnailUrl() {
    return this.thumbnail
      ? `${environment.resources}/inventory/${this.thumbnail}`
      : '';
  }

  imageUrl() {
    return this.image ? `${environment.resources}/inventory/${this.image}` : '';
  }
}
