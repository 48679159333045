export enum OrderStatus {
  Open = 'Open',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
}

export class OrderHistory {
  id: string;
  date: Date;
  status: OrderStatus;

  constructor(data: any = {}) {
    this.id = data.id;
    this.date = new Date(data.datetime * 1000);
    this.status = OrderStatus[data.status as keyof typeof OrderStatus];
  }
}

export class OrderBilling {
  cardType: string;
  cardNumber: string;

  constructor(data: any = {}) {
    this.cardType = data.credit_card_company;
    this.cardNumber = data.credit_card_number;
  }
}

export class Order {
  id: string;
  tax?: number;
  subtotal?: number;
  closedby: any;
  closeddate: any;
  paid: number;
  total: number;
  surcharge: number;
  bccharge: number;
  status: OrderStatus;
  createdAt: Date;
  venue: number;
  venuename: string;
  concession: number;
  concessionname: string;
  user: number;
  firstname: string;
  lastname: string;
  customerid: number;
  pickup: number;
  pickupname: string;
  paymenttype: string;
  tip: number;
  latitude: number;
  longitude: number;
  duration: number;
  opentime: number;
  waittime: number;

  constructor(data: any = {}) {
    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.status = OrderStatus[data.status as keyof typeof OrderStatus];
    this.createdAt = new Date(data.orderdate);
    this.total = +data.total;
    this.paid = +data.paid;
    this.subtotal = +data.subtotal;
    this.surcharge = +data.surcharge;
    this.bccharge = +data.bccharge;
    this.tax = +data.salestax;
    this.concession = data.concession;
    this.concessionname = data.concessionname;
    this.venue = data.venue;
    this.venuename = data.venuename;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.user = data.user;
    this.customerid = data.customerid;
    this.pickup = data.pickup;
    this.pickupname = data.pickupname;
    this.closedby = data.closedby;
    this.closeddate = data.closeddate;
    this.paymenttype = data.paymenttype;
    this.tip = data.tip;
    this.latitude = data.latitude;
    this.longitude = data.longitude;

    this.duration = data.duration;
    this.waittime = data.waittime;
    this.opentime = data.opentime;
  }
}
