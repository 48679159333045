import { LocalStorageItem } from '../config';

export class Event {
  id: string;
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  active: boolean;
  venue: string | number;
  venueid: string | number;
  showad: number;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  image1: string;
  image2: string;
  image3: string;
  courseimage: string;
  notificationphones: string;
  orderwaittime: number;
  maxorders: number;

  constructor(data: any = {}) {
    console.log(data);
    this.id = data.id;
    this.name = data.name;
    this.active = data.status == 'active';
    this.description = data.description;
    if (data.start_date) {
      this.startDate = new Date(data.start_date * 1000);
    } else if (data.startdate) {
      this.startDate = new Date(data.startdate);
    }

    if (data.end_date) {
      this.endDate = new Date(data.end_date * 1000);
    } else if (data.enddate) {
      this.endDate = new Date(data.enddate);
    }
    this.venue = data.venue;
    this.venueid = data.venueid;
    this.image1 = data.image1;
    this.image2 = data.image2;
    this.image3 = data.image3;
    this.courseimage = data.courseimage;

    this.showad = +data.showad;
    this.address = data.address;
    this.state = data.state;
    this.city = data.city;
    this.zipcode = data.zipcode;

    this.notificationphones = data.notificationphones;
    this.orderwaittime = data.orderwaittime;
    this.maxorders = data.maxorders;
  }

  static storeEventId(id: string) {
    localStorage.setItem(LocalStorageItem.EventId, id);
  }

  public static getStoredEventId(): string | null {
    const id = localStorage.getItem(LocalStorageItem.EventId);
    if (id) return id;
    return null;
  }
}
