export class ProductCategory {
  id: string;
  name: string;
  description: string;
  count: number;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.count = data.count;
  }
}
