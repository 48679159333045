import { environment } from 'src/environments/environment';
import { Event } from './event';
import { Venue } from './venue';

export enum ConcessionType {
  Concession = 'Concession',
  Cart = 'Beverage Cart',
  Marker = 'Marker',
}
export class Concession {
  id: string;
  name: string;
  lat: number;
  lng: number;
  latitude: number;
  longitude: number;
  image: string;
  icon: string;
  active: number;
  orderable: boolean;
  mobile: boolean;
  featured: number;
  eventid: string | number;
  venueid: string | number;
  eventname: string;
  venuename: string;
  title: string;
  profileimage: string;

  constructor(
    data: any = {},
    type: ConcessionType = ConcessionType.Concession
  ) {
    this.id = data.id;
    this.name = data.name;
    this.title = `${data.id} ${data.name}`;
    this.lat = +data.lat;
    this.lng = +data.lng;
    this.latitude = +data.latitude;
    this.longitude = +data.longitude;
    this.image = data.image;
    this.icon = data.icon;
    this.active = +data.active;
    this.featured = +data.featured;
    this.eventid = data.eventid;
    this.venueid = data.venueid;
    this.venuename = data.venuename;
    this.eventname = data.eventname;
    this.profileimage = data.profileimage;
    // this.orderable = !!data.orderable;
    // this.mobile = !!data.mobile;
    if (type == ConcessionType.Concession) {
      this.orderable = true;
      this.mobile = false;
    } else if (type == ConcessionType.Cart) {
      this.orderable = true;
      this.mobile = true;
    } else {
      this.orderable = false;
      this.mobile = false;
    }
  }

  public static getBaseRoute(type: ConcessionType) {
    switch (type) {
      case ConcessionType.Marker:
        return '/markers';
      case ConcessionType.Cart:
        return '/beverage-carts';
      default:
        return '/concessions';
    }
  }

  getType(): ConcessionType {
    if (this.mobile && this.orderable) {
      return ConcessionType.Cart;
    } else if (!this.mobile && this.orderable) {
      return ConcessionType.Concession;
    } else {
      return ConcessionType.Marker;
    }
  }

  profileimageUrl() {
    return this.profileimage;
  }
}
