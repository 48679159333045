import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { firstValueFrom } from 'rxjs';
import { Pickup } from '../models';

@Injectable()
export class SettingsService {
  constructor(private bs: BackendService) {}

  // Retrieve settings
  async getSettings() {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.settings.get));
    return res;
  }


  // Update an existing pickup
  async updateSettings(postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.put(this.bs.Api.settings.get,postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      data: data,
    };
  }
}
