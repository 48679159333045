import { environment } from 'src/environments/environment';

export interface ProductImage {
  id: string;
  url: string;
}

export class Product {
  id: string;
  name: string;
  title: string;
  description: string;
  order: string | number;
  image: string;
  thumbnail: string;
  category: string;
  venue: string | number;
  venuename: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.title = `${data.id} ${data.name}`;
      this.description = data.description;
      this.order = data.order;
      this.thumbnail = data.thumbnail;
      this.image = data.image;
      this.category = data.category;
      this.venue = data.venue;
      this.venuename = data.venuename;
    }
  }

  thumbnailUrl() {
    return this.thumbnail
      ? `${environment.resources}/inventory/${this.thumbnail}`
      : this.image
      ? `${environment.resources}/inventory/${this.image}`
      : '';
  }

  imageUrl() {
    return this.image ? `${environment.resources}/inventory/${this.image}` : '';
  }
}
