import { Injectable } from '@angular/core';
import { Tokens } from '../models/util/tokens';
import { firstValueFrom } from 'rxjs';
import { BackendService } from './backend.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Api, User, Venue } from '../models';
import { StoreService } from './store.service';
import { AppFields, AppTypes } from '../store';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {
  constructor(
    private bs: BackendService,
    private router: Router,
    private store: StoreService
  ) {}

  async sendSms({ phone }: { phone: string }) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.post(this.bs.Api.auth.logIn(), {
        username: phone,
        code: '',
      })
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error: error.Message ? error.Message : 'Something went wrong',
      };
    }

    const user = new User(data.user);
    if (user.role == 'admin') {
      return { success: true };
    } else if (user.role == 'venue_manager') {
      if (data.venues && data.venues.length > 0) {
        return { success: true };
      }
      return {
        success: false,
        error:
          'You have not been assigned to any Venues. Please contact Bevcarts.',
      };
    }

    return {
      success: false,
      error: 'This site is only available to Venue Administrators',
    };
  }

  async login({ phone, code }: { phone: string; code: string }) {
    let data: any;
    let error: any;
    if (environment.isLocal) {
      data = await firstValueFrom(this.bs.get(this.bs.Api.auth.logIn()));
    } else {
      data = await firstValueFrom(
        this.bs.post(this.bs.Api.auth.logIn(), {
          username: phone,
          code,
        })
      ).catch(e => {
        error = e;
        return null;
      });
    }

    if (!!error) {
      return {
        success: false,
        error: error.Message ? error.Message : 'Something went wrong',
      };
    }

    if (!data) {
      return { success: false };
    }

    this.doLoginUser(data);
    return { success: true };
  }

  async logInByToken(ts?: Tokens.TokenSet): Promise<{ success: boolean }> {
    const t = Tokens.getStored();
    if (!t) {
      return { success: false };
    }
    let data: any;
    if (environment.isLocal) {
      data = await firstValueFrom(
        this.bs.get(this.bs.Api.auth.loginByToken(t.token === 'admin'))
      );
    } else {
      data = await firstValueFrom(
        this.bs.post<object>(this.bs.Api.auth.loginByToken(false), {
          ...t,
        })
      ).catch(() => {
        Tokens.removeAll();
        return null;
      });
    }
    if (!data) {
      return { success: false };
    }
    this.doLoginUser(data);
    return { success: true };
  }

  doLoginUser(data: any) {
    if (data.jwtToken) {
      const user = new User(data.user);
      Tokens.store({
        token: data.jwtToken,
        refreshtoken: data.jwtRefreshToken,
        username: user.username,
      });
      if (data.venues) {
        user.venues = (data.venues as any[]).map(v => new Venue(v));
      }
      this.store.dispatch({
        type: AppTypes.UpdateState,
        payload: { [AppFields.User]: user },
      });
    }
  }

  async logOut(onAppInit = false) {
    const token = Tokens.getStored();
    if (token) {
      this.store.dispatch({
        type: this.store.Effect.app.UpdateState,
        payload: { [this.store.Fields.app.User]: null },
      });
      Tokens.removeAll();

      if (environment.isLocal) {
        firstValueFrom(this.bs.get(this.bs.Api.auth.logOut));
      } else {
        firstValueFrom(this.bs.post(this.bs.Api.auth.logOut, { token })).catch(
          console.error
        );
      }
    }
    if (!onAppInit) {
      this.router.navigate(['/auth/login']);
      setTimeout(() => location.reload(), 100);
    }
  }
}
