import { Concession } from './concession';
import { Venue } from './venue';

export class Pickup {
  id: string;
  name: string;
  concessionid: string | number;
  concessionname: string;
  venueid: string | number;
  venuename: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.concessionid = data.concessionid;
      this.venueid = data.venueid;
      this.concessionname = data.concessionname;
      this.venuename = data.venuename;
    }
  }
}
