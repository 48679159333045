import { authRoles } from 'src/app/models';
import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Main',
    isTitle: true,
  },
  {
    label: 'Dashboard',
    iconmdi: 'home-outline',
    link: '/dashboard',
  },
  {
    label: 'Manage',
    isTitle: true,
  },
  {
    label: 'Venues',
    iconmdi: 'golf',
    link: '/venues/list',
    auth: authRoles.admin,
  },
  {
    label: 'Events',
    iconmdi: 'calendar-text',
    link: '/events/list',
  },
  {
    label: 'Concessions',
    iconmdi: 'store',
    link: '/concessions/list',
  },
  {
    label: 'Beverage Carts',
    iconmdi: 'golf-cart',
    link: '/beverage-carts/list',
  },
  {
    label: 'Markers',
    iconmdi: 'map-marker',
    link: '/markers/list',
  },
  {
    label: 'Orders',
    iconmdi: 'cart-arrow-up',
    link: '/orders/list',
  },
  {
    label: 'Pickups',
    iconmdi: 'cart-arrow-up',
    link: '/pickups',
  },
  {
    label: 'Products',
    isTitle: true,
  },
  {
    label: 'Products',
    iconmdi: 'food',
    link: '/items/list',
  },
  {
    label: 'Categories',
    iconmdi: 'order-bool-descending-variant',
    link: '/items/categories',
  },
  {
    label: 'Options',
    iconmdi: 'radiobox-marked',
    link: '/items/extras',
  },
  {
    label: 'Inventory',
    iconmdi: 'warehouse',
    link: '/items/inventory',
  },
  {
    label: 'Admin',
    isTitle: true,
  },
  {
    label: 'Employees',
    iconmdi: 'account',
    link: '/users/list',
  },
  {
    label: 'Venue Managers',
    iconmdi: 'account',
    link: '/managers/list',
    auth: authRoles.admin,
  },
  {
    label: 'Notifications',
    iconmdi: 'bell',
    link: '/notifications',
    auth: authRoles.admin,
  },
  {
    label: 'Settings',
    iconmdi: 'cog',
    link: '/settings',
    auth: authRoles.admin,
  },
  {
    label: 'Reports',
    isTitle: true,
  },
  {
    label: 'Daily Sales Report',
    iconmdi: 'chart-line',
    link: '/report/daily-sales',
  },
  {
    label: 'Users Report',
    iconmdi: 'chart-pie',
    link: '/report/users',
  },
];
