import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { firstValueFrom } from 'rxjs';
import { Order, OrderItem, Paging } from '../models';

@Injectable()
export class OrderService {
  constructor(private bs: BackendService) {}

  // Retrieve orders with optional pagination
  async getAllOrders() {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.order.all));
    const orders: Order[] = res.map((p: any) => new Order(p));

    // Create paging information
    const paging: Paging = {
      page: 1,
      pageSize: orders.length,
      count: orders.length,
    };

    return {
      orders: orders,
      paging: paging,
    };
  }
  async getOrders(page?: number) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.order.list(page ?? 1))
    );
    const orders: Order[] = res.map((p: any) => new Order(p));

    // Create paging information
    const paging: Paging = {
      page: page ?? 1,
      pageSize: orders.length,
      count: orders.length,
    };

    return {
      orders: orders,
      paging: paging,
    };
  }

  // Retrieve orders associated with a venue
  async getVenueOrders(venueId: string) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.order.venue(venueId))
    );
    const orders: Order[] = res.map((p: any) => new Order(p));

    // Create paging information
    const paging: Paging = {
      page: 0,
      pageSize: orders.length,
      count: orders.length,
    };

    return {
      orders: orders,
      paging: paging,
    };
  }

  async getOpenOrdersByVenue(venueId: string) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.order.openvenue(venueId))
    );
    //const orders: Order[] = res.map((p: any) => new Order(p));
    return res ? (res as any[]).map(e => new Order(e)) : [];


  }

  async getOpenOrdersByEvent(eventId: string) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.order.openevent(eventId))
    );
    //const orders: Order[] = res.map((p: any) => new Order(p));
    return res ? (res as any[]).map(e => new Order(e)) : [];


  }

  // Retrieve orders associated with a concession
  async getConcessionOrders(concessionId: string) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.order.concession(concessionId))
    );
    const orders: Order[] = res.map((p: any) => new Order(p));

    // Create paging information
    const paging: Paging = {
      page: 0,
      pageSize: orders.length,
      count: orders.length,
    };

    return {
      orders: orders,
      paging: paging,
    };
  }

  // Retrieve orders associated with a cart
  async getCartOrders(cartId: string) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.order.cart(cartId))
    );
    const orders: Order[] = res.map((p: any) => new Order(p));

    // Create paging information
    const paging: Paging = {
      page: 0,
      pageSize: orders.length,
      count: orders.length,
    };

    return {
      orders: orders,
      paging: paging,
    };
  }

  // Retrieve a single order by ID
  async getOrder(id: string) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.order.get(id))
    );
    return res ? new Order(res) : undefined;
  }

  // Retrieve items associated with a specific order
  async getOrderItems(id: string) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.order.items(id))
    );
    const items: OrderItem[] = res.map((p: any) => new OrderItem(p));
    return items;
  }
}
