import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { firstValueFrom } from 'rxjs';
import { Venue } from '../models/entities/venue';
import { StoreService } from './store.service';

@Injectable()
export class VenueService {
  constructor(
    private bs: BackendService,
    private store: StoreService
  ) {}

  // Retrieve all venues, considering user's role and associated venues
  async getVenues() {

    // Retrieve venues from the backend
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.venue.list));

    // Fetch user information
    const user = await this.store.fetchUser();

    // Map backend response to Venue objects
    const allVenues = res ? (res as any[]).map(v => new Venue(v)) : [];

    // If the user is an admin, return all venues
    if (user.role === 'admin') {
      return allVenues;
    }

    // Filter venues based on the user's associated venues
    return allVenues.filter(e => !!user.venues.find(e1 => e1.id == e.id));
  }

  // Retrieve a single venue by ID
  async getVenue(id: string | number) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.venue.get(id))
    );

    return new Venue(res);
  }

  // Create a new pickup
  async create(postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.post(this.bs.Api.venue.list, postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }

    if (data) {
      return {
        success: true,
        id: data.id ? data.id : data.insertId,
      };
    }

    return {
      success: true,
    };
  }

  // Update an existing pickup
  async update(id: number | string, postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.put(this.bs.Api.venue.get(id), postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      data: data,
    };
  }
}
