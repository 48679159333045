export class Customer {
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phone?: string;
  constructor(data: any = {}) {
    this.id = data.id;
    this.firstName = data.firstname;
    this.lastName = data.lastname;
    this.email = data.email;
    this.phone = data.phone;
  }
}
