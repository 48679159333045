
export class Notification {
  id: number;
  userid: number;
  title: string;
  body: string;
  source: string;
  created: Date;
  event: number;
  venue: number;

  constructor(data: any = {}) {
    this.id = data.id;
    this.userid = data.userid;
    this.title = data.title
    this.source = data.source
    this.body = data.body
    this.created = new Date(data.created);

    this.event = data.event;
    this.venue = data.venue;

  }
}
