import { Venue } from './venue';

export const UserRolesEditable = ['admin', 'venue_manager', 'employee'];
export const UserRoles = ['admin', 'venue_manager', 'customer', 'employee'];
export const EmployeeRoles = ['employee'];
export const UserRoleMap: any = {
  admin: 'Admin',
  venue_manager: 'Venue Manager',
  customer: 'Customer',
  employee: 'Employee',
};

export class User {
  username: string;
  id: string;
  firstName?: string;
  lastName?: string;
  image?: string;
  email: string;
  phone?: string;
  role: string;
  dob: Date;
  venues: Venue[];
  venue?: number;
  concession?: number;
  customerid: string;
  constructor(data: any = {}) {
    this.username = data.username;
    this.id = data.id;
    this.firstName = data.firstname;
    this.lastName = data.lastname;
    this.email = data.email;
    this.phone = data.phonenumber;
    if (data.type == 'venue manager') {
      this.role = 'venue_manager';
    } else {
      this.role = data.type;
    }
    this.image = data.image;
    this.venue = data.venue;
    this.customerid = data.customerid;
    this.dob = new Date(Date.parse(data.dob));
    if (data.venues) {
      this.venues = (data.venues as any[]).map(v => new Venue(v));
    } else {
      this.venues = [];
    }
  }
}
