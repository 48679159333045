export class Location {
  id: string;
  name: string;
  lat: number;
  lng: number;
  image: string;
  icon: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.lat = +data.lat;
    this.lng = +data.lng;
    this.image = data.image;
    this.icon = data.icon;
  }
}
