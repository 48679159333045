import { User } from '../models';

export enum AppFields {
  App = 'app',
  User = 'user',
  Loading = 'loading',
}

export interface AppState {
  [AppFields.User]: User | null;
  [AppFields.Loading]: string | null;
}

export const InitialState: AppState = {
  [AppFields.User]: null,
  [AppFields.Loading]: null,
};

export const AppTypes = {
  UpdateState: 'update-app-state',
  UpdatePreferences: 'update-preferences',
};

export interface AppAction {
  type: string;
  payload?: any;
}
