import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Notification } from '../models';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private bs: BackendService) {}

  // Retrieve all notifications
  async getNotifications() {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.notification.list));
    const notifications: Notification[] = (res as any[]).map((p: any) => new Notification(p));
    return notifications;
  }

  // Retrieve specific notification
  async getNotification(id: string) {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.notification.get(id)));
    return res ? new Notification(res) : undefined;
  }

  // Retrieve all notifications by venue
  async getNotificationsByVenue(venue: string) {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.notification.venue(venue)));
    const notifications: Notification[] = (res as any[]).map((p: any) => new Notification(p));
    return notifications;
  }

  // Retrieve all notifications by event
  async getNotificationsByEvent(event: string) {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.notification.event(event)));
    const notifications: Notification[] = (res as any[]).map((p: any) => new Notification(p));
    return notifications;
  }


}
