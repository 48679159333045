import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { firstValueFrom } from 'rxjs';
import { Concession, ConcessionType, Order } from '../models';
import { Venue } from '../models/entities/venue';

@Injectable()
export class ConcessionService {
  constructor(private bs: BackendService) {}

  // Retrieve a specific concession by type and ID
  async getConcession(type: ConcessionType, id: number | string) {
    let res: any;
    if (type == ConcessionType.Marker) {
      res = await firstValueFrom(this.bs.get(this.bs.Api.marker.get(id)));
    } else if (type == ConcessionType.Cart) {
      res = await firstValueFrom(this.bs.get(this.bs.Api.cart.get(id)));
    } else {
      res = await firstValueFrom(this.bs.get(this.bs.Api.concession.get(id)));
    }

    return new Concession(res, type);
  }

  // Filter concessions by type, venue ID, and event ID
  async filterConcessions(
    type: ConcessionType,
    venueId?: string | number,
    eventId?: string | number
  ) {
    let res: any;
    if (type == ConcessionType.Marker) {
      res = await firstValueFrom(this.bs.get(this.bs.Api.marker.list));
    } else if (type == ConcessionType.Cart) {
      res = await firstValueFrom(this.bs.get(this.bs.Api.cart.list));
    } else {
      res = await firstValueFrom(this.bs.get(this.bs.Api.concession.list));
    }

    const concessions: Concession[] = res
      ? (res as any[]).map(e => new Concession(e, type))
      : [];

    return concessions.filter(c => {
      // Filter by event ID if provided
      if (eventId && eventId != -1 && c.eventid != eventId) return false;
      return true;
    });
  }

  // Retrieve concessions associated with a venue by type
  async getVenueConcessions(type: ConcessionType) {
    let res: any;
    if (type == ConcessionType.Marker) {
      res = await firstValueFrom(this.bs.get(this.bs.Api.marker.list));
    } else if (type == ConcessionType.Cart) {
      res = await firstValueFrom(this.bs.get(this.bs.Api.cart.list));
    } else {
      res = await firstValueFrom(this.bs.get(this.bs.Api.concession.list));
    }

    return res ? (res as any[]).map(e => new Concession(e, type)) : [];
  }

  // Retrieve concessions associated with an event by type
  async getEventConcessions(type: ConcessionType, eventId: string) {
    let res: any;
    if (type == ConcessionType.Marker) {
      res = await firstValueFrom(
        this.bs.get(this.bs.Api.marker.event(eventId))
      );
    } else if (type == ConcessionType.Cart) {
      res = await firstValueFrom(this.bs.get(this.bs.Api.cart.event(eventId)));
    } else {
      res = await firstValueFrom(
        this.bs.get(this.bs.Api.concession.event(eventId))
      );
    }

    return res ? (res as any[]).map(e => new Concession(e, type)) : [];
  }

    // Retrieve active carts associated with an event by type
    async getEventCarts(eventId: string) {
      let res: any;

      res = await firstValueFrom(this.bs.get(this.bs.Api.cart.eventactive(eventId)));


      return res ? (res as any[]).map(e => new Concession(e, ConcessionType.Cart)) : [];
    }

  // Create a new concession
  async createConcession(type: ConcessionType, postData: any) {
    let error: any;
    let endpoint = this.bs.Api.concession.list;
    if (type == ConcessionType.Cart) {
      endpoint = this.bs.Api.cart.list;
    } else if (type == ConcessionType.Marker) {
      endpoint = this.bs.Api.marker.list;
    }

    const data = await firstValueFrom(this.bs.post(endpoint, postData)).catch(
      e => {
        error = e;
        return null;
      }
    );

    if (!!error) {
      return {
        success: false,
        error,
      };
    }

    if (data) {
      return {
        success: true,
        id: data.id ? data.id : data.insertId,
      };
    }

    return {
      success: true,
    };
  }

  // Update an existing concession
  async updateConcession(
    type: ConcessionType,
    id: number | string,
    postData: any
  ) {
    let error: any;
    let endpoint = this.bs.Api.concession.get(id);
    if (type == ConcessionType.Cart) {
      endpoint = this.bs.Api.cart.get(id);
    } else if (type == ConcessionType.Marker) {
      endpoint = this.bs.Api.marker.get(id);
    }

    const data = await firstValueFrom(this.bs.put(endpoint, postData)).catch(
      e => {
        error = e;
        return null;
      }
    );

    if (!!error) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      data: data,
    };
  }
}
