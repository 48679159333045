import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { first, firstValueFrom } from 'rxjs';
import {
  Order,
  Paging,
  Product,
  ProductOption,
  ProductOptionSet,
} from '../models';
import { Inventory } from '../models/entities/inventory';

@Injectable()
export class ProductService {
  constructor(private bs: BackendService) {}

  async getProduct(id: number | string) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.product.get(id))
    );
    const product = res ? new Product(res[0]) : undefined;
    return product;
  }

  async getProducts(page?: number, category?: number | string) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.product.list(page ?? 1, category ?? -1))
    );
    const products: Product[] = res.map((p: any) => new Product(p));
    const paging: Paging = {
      page: 0,
      pageSize: products.length,
      count: products.length,
    };
    return {
      products: products,
      paging: paging,
    };
  }

  async getAllProducts() {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.product.all));
    const products: Product[] = res.map((p: any) => new Product(p));
    return products;
  }

  async updateProduct(id: string | number, postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.put(this.bs.Api.product.get(id), postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }
    return {
      success: true,
      data: data,
    };
  }

  async createProduct(postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.post(this.bs.Api.product.all, postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }
    return {
      success: true,
      id: data.id ? data.id : data.insertId,
    };
  }

  async getOptionSets() {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.optionset.list)
    );
    const optionsets: ProductOptionSet[] = res
      ? res.map((p: any) => new ProductOptionSet(p))
      : [];
    return optionsets;
  }

  async getOptionSet(id: number | string) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.optionset.get(id))
    );
    return res ? new ProductOptionSet(res) : undefined;
  }

  async updateOptionSet(id: string | number, postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.put(this.bs.Api.optionset.get(id), postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }
    return {
      success: true,
      data: data,
    };
  }

  async createOptionSet(postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.post(this.bs.Api.optionset.list, postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }
    return {
      success: true,
      id: data.id ? data.id : data.insertId,
    };
  }

  async getOptions() {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.option.list));
    const options = res ? res.map((p: any) => new ProductOption(p)) : [];
    return options;
  }

  async getOption(id: number | string) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.option.get(id))
    );

    return res ? new ProductOption(res) : undefined;
  }

  async updateOption(id: string | number, postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.put(this.bs.Api.option.get(id), postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }
    return {
      success: true,
      data: data,
    };
  }

  async createOption(postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.post(this.bs.Api.option.list, postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }
    return {
      success: true,
      id: data.id ? data.id : data.insertId,
    };
  }

  async getInventoryItems() {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.inventory.list)
    );
    const items: Inventory[] = res ? res.map((p: any) => new Inventory(p)) : [];
    return items;
  }

  async getInventoryItem(id: string | number) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.inventory.get(id))
    );
    return res && res.length > 0 ? new Inventory(res[0]) : undefined;
  }

  async getVenueInventoryItems(venueid: string | number) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.inventory.venue(venueid))
    );
    const items: Inventory[] = res ? res.map((p: any) => new Inventory(p)) : [];
    return items;
  }

  async getConcessionInventoryItems(concessionid: string | number) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.inventory.concession(concessionid))
    );
    const items: Inventory[] = res ? res.map((p: any) => new Inventory(p)) : [];
    return items;
  }

  async createInventoryItem(postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.post(this.bs.Api.inventory.list, postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }
    return {
      success: true,
      id: data.id ? data.id : data.insertId,
    };
  }

  async updateInventoryItem(id: string | number, postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.put(this.bs.Api.inventory.get(id), postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }
    return {
      success: true,
      data: data,
    };
  }

  async getInventoryItemsForProduct(productId: string | number) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.inventory.list)
    );
    const items: Inventory[] = res ? res.map((p: any) => new Inventory(p)) : [];
    return items.filter(item => item.item == productId);
  }
}
