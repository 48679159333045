export class Address {
  address: string;
  city: string;
  state: string;
  zip: string;

  constructor(data: any = {}) {
    this.address = data.address;
    this.city = data.city;
    this.state = data.state;
    this.zip = data.zip;
  }

  toString() {
    return [this.address, this.city, this.state, this.zip]
      .filter(e => !!e)
      .join(', ');
  }
}
