import { LocalStorageItem } from '../config';

export class APIKeys {
  id: string;
  venue: number;
  apikey: string;
  active: boolean;
  created: Date;


  constructor(data: any = {}) {
    if (!data) {
      data = {};
    }
    this.id = data.id;
    this.venue = data.venue;
    this.apikey = data.apikey;
    this.active = data.active;
    this.created = data.created;
  }


}
