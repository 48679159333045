import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { firstValueFrom } from 'rxjs';
import { Pickup } from '../models';

@Injectable()
export class PickupService {
  constructor(private bs: BackendService) {}

  // Retrieve all pickups
  async getPickups() {
    const res: any = await firstValueFrom(this.bs.get(this.bs.Api.pickup.list));
    const pickups: Pickup[] = res ? res.map((p: any) => new Pickup(p)) : [];
    return pickups;
  }

  async getVenuePickups(venueid: string | number) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.pickup.venue(venueid))
    );
    const pickups: Pickup[] = res ? res.map((p: any) => new Pickup(p)) : [];
    return pickups;
  }

  async getConcessionPickups(concessionId: string | number) {
    const res: any = await firstValueFrom(
      this.bs.get(this.bs.Api.pickup.concession(concessionId))
    );
    const pickups: Pickup[] = res ? res.map((p: any) => new Pickup(p)) : [];
    return pickups;
  }

  // Create a new pickup
  async create(postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.post(this.bs.Api.pickup.list, postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }

    if (data) {
      return {
        success: true,
        id: data.id ? data.id : data.insertId,
      };
    }

    return {
      success: true,
    };
  }

  // Update an existing pickup
  async update(id: number | string, postData: any) {
    let error: any;
    const data = await firstValueFrom(
      this.bs.put(this.bs.Api.pickup.get(id), postData)
    ).catch(e => {
      error = e;
      return null;
    });

    if (!!error) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      data: data,
    };
  }
}
