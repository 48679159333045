import { Product } from './product';
import { ProductOptionSet } from './product-option-set';

export enum ProductOptionType {
  option = 'option',
  addon = 'addon',
}

export class ProductOption {
  id: string;
  name: string;
  price: number;
  editing: boolean;
  inventoryid: number;
  category: number;
  vieworder: number;
  checked: number;
  type: ProductOptionType;

  constructor(data?: any) {
    this.editing = false;
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.price = data.price;
      this.inventoryid = data.inventoryid;
      this.category = data.category;
      this.vieworder = data.vieworder;
      this.checked = data.checked;
      this.type =
        ProductOptionType[data.extratype as keyof typeof ProductOptionType];
    }
  }
}
